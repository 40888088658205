<template>
  <div :key="`input-field-${label}`" class="input-line">
    <div class="label pro-treble">{{ label }}</div>
    <input :value="value" @input="$emit('update:value', $event.target.value)" class="input" :placeholder="getPlaceholder"/>
  </div>
</template>

<script>
export default {
  name: "input-field",
  props: {
    value: {required: true},
    label: {default: 'no-label'},
    required: {required: false, type: Boolean, default: false},
    placeholder: {required: false, type: String},
  },
  emits: ['update:value'],
  data() {
    return {
    }
  },
  computed: {
    getPlaceholder() {
      if (this.placeholder) {
        return this.placeholder
      } else {
        return this.required ? this.$t('trainer-form-required-field') : undefined
      }
    }
  }
}
</script>

<style scoped lang="scss">

.input-line {
  margin-bottom: 2em;

  .input {
    border: none;
    background: #ece9e8;
    border-radius:0;
    padding: 1.5em;
    font-family: 'ProDouble', sans-serif;
    letter-spacing: .25px;

    &::placeholder {
      color: #2d2d2d;
      opacity: .4;
    }
  }

}

</style>
