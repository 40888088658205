<template>
  <div class="start-screen">
    <div class="start-panel">
      <qr-scanner-inline @scanned="scannedCode" key="first" v-if="ready"/>
    </div>
  </div>
</template>

<script>
import QrScannerInline from "@/components/qr-scanner-inline";

export default {
  name: "scan",
  components: {QrScannerInline},

  data() {
    return {
      scanned: false,
      ready: false,
    }
  },

  mounted() {
    this.ready = true;
  },

  methods: {

    async checkQR() {
      await this.$store.dispatch('VALIDATE_QR', this.$api)
          .then(() => {
            this.$setLocale(this.$store.state.lang)
            setTimeout(() => {
              this.$store.dispatch('SET_VIEW', 'scan-confirm');
            }, 1000)
          })
    },

    scannedCode(result) {
      // take first scan.
      if (!this.scanned) {
        this.scanned = true;

        let parameters = result.split('/#/?')
        if (!parameters[1]) {
          this.$notify.error(this.$t('camera-scan-code-error'));
          this.key++;
        }

        let params = new URLSearchParams(parameters[1]);
        this.$store.dispatch('STORE_CQR', params.get('cqr'));
        this.$nextTick(() => {
          this.checkQR();
        })

      }
    }
  }
}
</script>

<style scoped lang="scss">

.start-screen {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 1em;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
  }

  .start-panel {
    position: relative;
    z-index: 2;
    border-radius: 1em;
    padding: 0 2em 2em;

    .description {
      font-size: 1.5em;
      text-align: center;
      line-height: 1.2em;
      font-weight: 400;
    }

    .buttons {
      margin-top: 1em;

      .start {
        font-size: 1.2em;
        padding: 0.7em 1em;
        background: #6131ad;
        text-transform: uppercase;
        text-align: center;
        font-weight: 400;

        &:active {
          background: #4b238c;
        }
      }

    }
  }
}
</style>
