<template>
  <div :key="`input-thing-${label}`" class="input-line-checkbox">

    <label>
      <input type="checkbox" :checked="value" @change="$emit('update:value', $event.target.checked)"
             class="input-checkbox"/>
      <span class="checkmark"/>
      {{ label }}
    </label>

  </div>
</template>

<script>
export default {
  name: "input-checkbox",
  props: {
    label: {},
    value: {}
  }
}
</script>

<style scoped lang="scss">

.input-line-checkbox {
  position: relative;
  line-height:140%;
  margin-bottom: 2em;

  .checkmark {
    position: relative;
    //top: 0;
    //left: 0;
    display: block;
    height: 25px;
    width: 25px;
    background-color: #ece9e8;
    margin-bottom: 0.75em;

    &:after {
      content: "";
      position: absolute;

      left: 9px;
      top: 5px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      opacity: 0;
      transition: opacity $easing $transition-duration;
    }
  }

  input.input-checkbox {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked {
      ~ .checkmark {
        background: $color-primary;

        &:after {
          opacity: 1;
          //display: block;
        }
      }
    }
  }


}


</style>
