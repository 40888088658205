import {createStore} from 'vuex'

import Session from './session';

const Store = createStore({
  modules: {
    Session
  },
  state: {
    lang: 'nl',
    usageType: undefined,

    cqr: undefined,

    lesson: {
      trainer: {
        firstname: undefined,
        lastname: undefined,
        idEmail: undefined,
      },
      name: undefined,
      duration: undefined,
      displayTrainerName: true,
      displayDuration: true,
    },
    routerView: undefined,
    lessons: []
  },
  mutations: {
    setView(state, view) {
      state.routerView = view;
    },

    setType(state, value) {
      state.usageType = value;
    },

    storeCQR(state, value) {
      state.cqr = value;
    },
    setLang(state, value) {
      state.lang = value
    },

    setTrainer(state, trainer) {
      state.lesson.trainer = trainer;
    },
    setLesson(state, lesson) {
      state.lesson = Object.assign(state.lesson, lesson);
    },
    setSession(state, session) {
      state.Session = session;
    },
    resetLesson(state) {
      state.lesson = Object.assign(state.lesson, {
        name: undefined,
        duration: undefined,
        displayTrainerName: true,
        displayDuration: true,
      })
    },
    resetFull(state) {
      state.usageType = undefined;
      state.cqr = undefined;

      state.lesson = {
        trainer: {
          firstname: undefined,
          lastname: undefined,
          idEmail: undefined,
        },
        name: undefined,
        duration: undefined,
        displayTrainerName: true,
        displayDuration: true
      }
    },
    setLessons(state, data) {
      state.lessosn = data;
    }
  },
  actions: {
    SET_TYPE({commit, dispatch}, type) {
      commit('setType', type);
    },
    STORE_CQR({commit, dispatch}, value) {
      commit('storeCQR', value);
      // dispatch('SAVE_SESSION');
    },
    SET_LANG({commit}, lang) {
      commit('setLang', lang.toLowerCase());
    },
    STORE_TRAINER({commit, dispatch}, trainer) {
      commit('setTrainer', trainer);
      dispatch('SAVE_SESSION');
    },
    STORE_LESSON({commit, dispatch}, lesson) {
      commit('setLesson', lesson);
      dispatch('SAVE_SESSION');
    },
    RESET_LESSON({commit, dispatch}, full = false) {
      if (full) {
        commit('resetFull');
      } else {
        commit('resetLesson');
        dispatch('SAVE_SESSION');
      }
    },

    RESET({commit}) {
      commit('Session/resetSession', {root: true});
      commit('resetFull');
      localStorage.clear();
    },

    SAVE_SESSION({state}) {
      let data = {
        suuid: state.Session.session_uuid,
        puuid: state.Session.player_uuid,
        type: state.usageType,
        lang: state.lang,
        cqr: state.cqr,
        lesson: state.lesson,
        session: state.Session,
        view: state.routerView
      };
      // console.log('want to save all this"', data, state.Session)
      localStorage.setItem(process.env.VUE_APP_SESSION_STORAGE_KEY, JSON.stringify(data))
    },

    RESTORE_SESSION({commit}, data) {
      commit('Session/restoreSession', data.session, {root: true});
      commit('setType', data.type)
      commit('setLesson', data.lesson)
      commit('setLang', data.lang)
      commit('storeCQR', data.cqr)
      commit('setView', data.view)
    },

    SET_VIEW({state, commit, dispatch}, view) {
      if (state.routerView !== view) {
        commit('setView', view);
        dispatch('SAVE_SESSION')
      }
    },

    VALIDATE_QR({state, dispatch}, api) {
      return api.get(`/api/corporate-qr/${state.cqr}/new-session`)
          .then((res) => {
            dispatch('Session/STORE_SESSION_RESPONSE', res.data, {root: true})
          })
          .catch((e) => {
            if (e.response.status === 404) {
              dispatch('SET_VIEW', 'assign-club');
            } else {
              dispatch('SET_VIEW','home');
            }
          })
    }
  },
  getters: {
    usageType(state) {
      return state.usageType
    },

    cqr(state) {
      return state.cqr;
    },

    getTrainer(state) {
      return state.lesson.trainer;
    },

    getLesson(state) {
      const item = state.Session.selectedItem
      if (item) {
        const cat = item.meta_data.find((m) => m.name === 'MediaItem.VW_Category').value;
        const duration = parseInt(item.meta_data.find((m) => m.name === 'MediaItem.VW_Duration_min').value);
        const isTrainer = cat.toLowerCase() === 'trainer';

        return {
          category: cat,
          trainerName: `${state.lesson.trainer.firstname} ${state.lesson.trainer.lastname}`,
          trainerTime: isTrainer ? state.lesson.duration : duration,
          trainerShowName: state.lesson.displayTrainerName,
          trainerShowTime: state.lesson.displayDuration,
          trainerTrainingName: isTrainer ? state.lesson.name : item.name,
          trainerID: isTrainer ? state.lesson.trainer.idEmail : 'Employee',
          trainerLanguage: state.lang
        }
      }
      return undefined
    },
    getView(state) {
      return state.routerView
    },
    getLessons(state) {
      return state.lessons
    }
  }
})

export default Store;
