<template>
  <div class="form-container">
    <div class="form">
      <input-field v-model:value="trainer.firstname" :label="$t('firstname')" :required="true"/>
      <input-field v-model:value="trainer.lastname" :label="$t('lastname')" :required="true"/>
      <input-field v-model:value="trainer.idEmail" :label="$t('trainer-form-id-email')" :required="true"/>
    </div>

    <div>
      <bf-button @onClick="clearAndBack" type="secondary">
        {{ $t('back') }}
      </bf-button>

      <bf-button :disabled="!canSubmit" @onClick="storeTrainerAndNext">
        {{ $t('next') }}
      </bf-button>
    </div>
  </div>
</template>

<script>
import InputField from "@/components/inputs/input-field";
import BfButton from "@/components/bf-button";

export default {
  name: "defineTrainer",
  components: {BfButton, InputField},
  data() {
    return {
      trainer: {
        firstname: undefined,
        lastname: undefined,
        idEmail: undefined
      }
    }
  },

  computed: {
    canSubmit() {
      return this.trainer.firstname && this.trainer.lastname && this.trainer.idEmail
    }
  },

  mounted() {
    this.trainer = this.$store.getters['getTrainer'];
  },

  methods: {
    clearAndBack() {
      this.$store.dispatch('SET_TYPE', undefined);
      this.$store.dispatch('SET_VIEW', 'select-start');
    },

    storeTrainerAndNext() {
      this.$store.dispatch('STORE_TRAINER', this.trainer)

      this.$nextTick(() => {
        this.$store.dispatch('SET_VIEW', 'select-trainer-lesson');
      })
    }
  }
}
</script>

<style scoped>

</style>
