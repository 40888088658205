<template>

  <div class="app-filter pro-double" ref="select" :style="{width: width}">

    <div class="label pro-treble">{{ label }}</div>

    <div class="filter-input capitalize" @click="toggleOpenState"
         :class="{active: displayOptions}">
      <span :style="!selected ? {color: 'rgb(45 45 45 / 40%)', textTransform: 'lowercase', fontWeight: 500} : {}">
        {{ formattedOption(selected) || $t('trainer-from-choose-an-option') }}
      </span>
      <awesome-icon icon="chevron-down" style="margin-left: 1em;"/>
    </div>

    <template v-if="displayOptions">
      <div class="blackout"/>

      <div class="modal">
        <div class="modal-title pro-treble">{{ modalTitle ? modalTitle : label }}</div>

        <template v-if="search">
          <input v-model="searchValue" :placeholder="$t('trainer-form-search')" class="search-input"/>
        </template>

        <div class="filter-options" ref="options">
          <div class="filter-option" v-for="(o,i) in filteredOptions"
               :key="`option-${i}`"
               @click.stop.prevent="selectOption(o)">
            <span class="filter-value capitalize" :class="{selected: isSelected(o)}"
                  :ref="`option-${o}`">
              {{ formattedOption(o) }}
            </span>
          </div>
        </div>

        <bf-button @click="onContinue" type="plain" style="float:right;">{{ $t('trainer-time-select-continue') }}</bf-button>
      </div>
    </template>
  </div>

</template>

<script>
import BfButton from "@/components/bf-button";

export default {
  name: "input-scroll-select",
  components: {BfButton},
  props: {
    label: {},
    options: {required: true, type: Array},
    width: {default: '100%'},
    value: {},
    format: {required: false, type: String},
    search: {required: false, type: Boolean, default: false},
    modalTitle: {required: false, type: String}
  },
  data() {
    return {
      displayOptions: false,
      bounds: undefined,
      selected: undefined,
      searchValue: undefined
    }
  },

  beforeMount() {
    if (this.value !== undefined) {
      this.selected = this.value;
    }
  },

  computed: {
    filteredOptions() {
      if (this.search && this.searchValue !== undefined) {
        return this.options.filter((o) => {
          return o.toLowerCase().indexOf(this.searchValue.toLowerCase()) !== -1
        })
      }

      return this.options;
    }
  },

  methods: {
    isSelected(value) {
      return this.selected === value;
    },

    toggleOpenState() {
      this.displayOptions = !this.displayOptions;
      this.scroll();
    },

    selectOption(o) {
      this.selected = o;
    },

    onContinue() {
      this.displayOptions = false;
      this.$emit('update:value', this.selected);
    },

    formattedOption(value) {
      if (this.format) {
        if (!this.format.includes('{value}')) {
          throw new Error('Missing {value} in string. This will be replaced with actual value');
        }
        return this.format.replace('{value}',value);

      } else {
        return value
      }
    },

    scroll() {
      this.$nextTick(() => {
        if (this.selected) {
          let targetScroll = this.$refs[`option-${this.selected}`][0].offsetTop - 52;
          this.$refs.options.scrollTo(0, targetScroll);
        }
      })
    }
  }
}

</script>

<style scoped lang="scss">

.app-filter {
  cursor: pointer;
  box-sizing: border-box;
  margin-bottom: 2em;

  .label {
    text-transform: uppercase;
    font-size: 32px;
    font-weight: 700;
    margin-bottom: .33em;
    line-height: 120%;
  }

  .filter-input {
    background: #ece9e8;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.25em;

    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 14px;
    }

    &.active {
      color: #FFF;
      background: $color-primary;
    }
  }

  .blackout {
    background: rgba(0, 0, 0, 0.4);
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 5;
  }


  .modal {
    background: #f4f4f4;
    position: absolute;
    z-index: 10;
    width: 100%;
    padding: 1.25em;
    max-width: 90vw;
    box-sizing: border-box;
    max-height: 60vh;
    overflow: hidden;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.25em;
    font-family: 'ProDouble', sans-serif;

    .modal-title {
      text-transform: uppercase;
      font-weight: 600;
    }

    .search-input {
      border: none;
      margin-top: 1em;
      border-radius: 0;
      font-family: 'ProDouble', sans-serif
    }
  }


  .filter-options {
    position: relative;
    box-shadow: var(--vc-shadow-light);
    box-sizing: border-box;
    overflow-y: scroll;
    overflow-x: hidden;
    max-width: 90%;
    scroll-snap-type: y mandatory;
    height: 150px;
    display: flex;
    flex-flow: column nowrap;
    margin: 1.5em auto;
    align-items: center;

    .filter-option {
      padding: .75em .6em;
      display: flex;
      //width: 50%;
      max-width: inherit;
      justify-content: center;
      align-items: center;
      text-transform: lowercase;

      border-bottom: 1px solid #e1e1e1;

      &:last-child {
        border-bottom: none;
      }

      .filter-value {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        opacity: .5;

        &.selected {
          opacity: 1;
        }
      }
    }
  }
}

</style>
