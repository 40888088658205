<template>
  <div class="form-container">
    <div class="label">{{ $t('trainer-choose-background') }}</div>

    <bf-video-select :items="filteredItems" @onSelect="selected" />

    <div class="buttons">
      <bf-button @onClick="back" type="secondary">
        {{ $t('back') }}
      </bf-button>
    </div>
  </div>
</template>

<script>
import BfButton from "@/components/bf-button";
import BfVideoSelect from "@/components/bf-video-select";

export default {
  name: "selectBackground",
  components: {BfVideoSelect, BfButton},
  computed: {
    filteredItems() {
      return this.$store.getters['Session/getMedia'].filter((i) => {
        let index = i.meta_data.findIndex((m) => m.name === 'MediaItem.VW_Category');
        if (index !== -1) {
          return i.meta_data[index].value.toLowerCase() === 'trainer'
        } else {
          return false;
        }
      })
    }
  },
  methods: {
    back() {
      this.$store.dispatch('SET_VIEW','select-trainer-lesson');
    },

    selected() {
      this.$store.dispatch('SET_VIEW','select-trainer-result');
    }
  }
}
</script>

<style scoped lang="scss">

.buttons {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #FFF;
  height: 80px;
  box-sizing: border-box;

  button {
    position: absolute;
    bottom: 1em;
  }
}

</style>
