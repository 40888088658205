<template>
  <div class="scanner" @click="$emit('close')">
    <video autoplay loop muted playsinline ref="bgVideo" class="bg-video"/>
    <div class="video-container">
      <video ref="scanner"/>
    </div>
  </div>
</template>

<script>
import QrScanner from 'qr-scanner';

export default {
  name: "qr-scanner-inline",
  data() {
    return {
      bgVideo: undefined,
      scanner: undefined
    }
  },

  mounted() {
    this.bgVideo = this.$refs.bgVideo;
    this.initiate();
  },

  beforeUnmount() {
    this.clear();
  },

  methods: {
    onScanResult(result) {
      this.$emit('scanned', result.data);
      this.scanner.stop();
    },

    onScanError(error) {
      // Do nothing keep scanning until result is found;
    },

    clear() {
      if (this.scanner) {
        this.scanner.stop();
        this.scanner.destroy();
        this.scanner = null;
      }
      this.$refs.bgVideo.srcObject = null;
    },

    initiate() {
      this.scanner = new QrScanner(this.$refs.scanner, this.onScanResult, {
        setCamera: 'environment',
        onDecodeError: this.onScanError,
      })

      this.scanner.start()
          .then(() => {
            this.$refs.bgVideo.srcObject = this.scanner.$video.srcObject
            this.$refs.bgVideo.play();
          })
          .catch(() => {
            setTimeout(() => {
              // todo remove line below
              this.$notify.error('could not init scanner')
              this.clear();
              localStorage.clear();
              this.$store.dispatch('RESET');
              this.initiate()
            },500)
          })
    }
  }
}
</script>

<style scoped lang="scss">

.starting,
.instruction {
  width: 100%;
  margin: 1em 0;
  text-align: center;
}

.starting .text,
.instruction .text {
  font-size: 0.8em;
  font-weight: 700;
  text-transform: uppercase;
}

video {
  width: 100%;
  border-radius: 0.3em;
  background: #111;
}

canvas {
  display: none;
}

.bg-video {
  position: fixed;
  width: 150vw;
  height: 150vh;
  object-fit: cover;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  filter: blur(10px);
  background-color: $color-text;
}

.scanner {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $color-text;
  display: flex;
  justify-content: center;
  align-items: center;

  .video-container {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 1;
    position: relative;
    border: 1px solid white;
    padding: 1em;
    box-sizing: border-box;

    .scan-video {
      object-fit: cover;
      width: 640px;
      height: 640px;
      max-width: 640px;
      max-height: 640px;

    }

    &::after {
      display: block;
      position: absolute;
      content: '';
      top: -3px;
      lefT: -3px;
      right: -3px;
      bottom: -3px;

      --size: 5px;

      background: linear-gradient(to right, $color-primary var(--size), transparent var(--size)) 0 0,
      linear-gradient(to right, $color-primary var(--size), transparent var(--size)) 0 100%,
      linear-gradient(to left, $color-primary var(--size), transparent var(--size)) 100% 0,
      linear-gradient(to left, $color-primary var(--size), transparent var(--size)) 100% 100%,
      linear-gradient(to bottom, $color-primary var(--size), transparent var(--size)) 0 0,
      linear-gradient(to bottom, $color-primary var(--size), transparent var(--size)) 100% 0,
      linear-gradient(to top, $color-primary var(--size), transparent var(--size)) 0 100%,
      linear-gradient(to top, $color-primary var(--size), transparent var(--size)) 100% 100%;

      background-repeat: no-repeat;
      background-size: 20px 20px;
    }
  }
}


</style>
