<template>
  <button class="bf-button pro-treble" @click.stop.prevent="$emit('onClick')" :disabled="disabled" :class="getClass">
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: "bf-button",
  props: {
    disabled: {type: Boolean, default: false},
    type: {required: false, type: String}
    // secondary: {require: false, default: false}
  },
  computed: {
    getClass() {
      return this.type
    }
  }
}
</script>

<style scoped lang="scss">

.bf-button {
  color: #FFF;
  background-color: $color-secondary;
  border: none;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 600;
  width: fit-content;
  padding: .75em 1em;
  margin: 0 .5em;

  transition: opacity $easing $transition-duration;

  &:first-child {
    margin-left: 0;
  }

  &.secondary {
    color: initial;
    background-color: $color-background;
  }

  &.plain {
    text-transform: capitalize;
    color: initial;
    background-color: transparent;
    font-weight: normal;
  }

  &:disabled {
    opacity: .5;
  }
}

</style>
