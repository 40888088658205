import {createApp} from 'vue';
import Root from './views/App.vue';
import Store from "@/store";
import Router from "./router";

const App = createApp(Root)
    // .use(Router)
    .use(Store);

import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(fas);
App.component('awesome-icon', FontAwesomeIcon);
//
import Notify from './plugins/notification-panel'
App.use(Notify, {Store})
//
import Translations from '@scala-services/scala-translations'

// Use plugin, single file
App.use(Translations, {
  locale: 'en',
  fallback: 'en',
  locales: {
    'be-fr': require('./assets/translations/be-fr.json'),
    'be-nl': require('./assets/translations/be-nl.json'),
    'es': require('./assets/translations/es.json'),
    'fr': require('./assets/translations/fr.json'),
    'lux': require('./assets/translations/lux.json'),
    'nl': require('./assets/translations/nl.json'),
  }
});

import axios from 'axios'
App.config.globalProperties.$api = axios.create({
  baseURL: process.env.VUE_APP_URL,
  headers: {
    'Authorization': `Bearer ${process.env.VUE_APP_BEARER}`
  }
})

App.config.globalProperties.$debug = process.env.VUE_APP_DEBUG === 'true';

// Mount application
App.mount('#app')
