<template>
  <div class="video-select-item" :class="displayTitle ? type : ''">
    <div class="thumbnail">
      <img v-if="getMediaThumbnail" :src="getMediaThumbnail"/>
      <img v-else :src="require('@/assets/img/Basic-Fit-GXR-Shape-Dennis-Baffoe-BNL2018.jpg')"/>
    </div>
    <div class="video-select-item-name" v-if="displayTitle">{{ item.name }}</div>
  </div>
</template>

<script>
export default {
  name: "bf-video-item",
  props: {
    item: {required: true, type: Object},
    displayTitle: {require: false, default: true, type: Boolean}
  },
  computed: {
    type() {
      return this.$store.getters['usageType']
    },
    getMediaThumbnail() {
      if (![undefined, null, ''].includes(this.item.thumbnail)) {
        return process.env.VUE_APP_URL + '/' + this.item.thumbnail;
      }
    }
  },
}
</script>

<style scoped lang="scss">
.video-select-item {
  width: 100%;
  position: relative;
  aspect-ratio: 1 / 1;
  max-height: 300px;
  box-sizing: border-box;
  margin-bottom: 2em;
  //overflow: hidden;

  .thumbnail {
    width: 100%;
    height: 100%;
    display: flex;

    img {
      width: inherit;
      height: inherit;
      object-fit: cover;
    }
  }

  &.trainer {
    aspect-ratio: 16 / 8;
    margin-bottom: 4em;

    .video-select-item-name {
      position: absolute;
      bottom: -1.75em;
      text-transform: capitalize;
    }
  }

  &.employee {
    margin-bottom: 2em;
    aspect-ratio: 16 / 9;
    display: flex;
    align-items: center;

    .video-select-item-name {
      position: absolute;
      font-size: 30px;
      margin: .75em;
      max-width: 60%;
      line-height: 120%;
      font-weight: 600;
      text-transform: uppercase;
      color: #FFF;
    }
  }
}
</style>
