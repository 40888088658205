<template>
  <div class="container">
    <define-trainer v-if="trainerView === 'trainer'"/>
    <define-lesson v-else-if="trainerView === 'lesson'"/>
    <select-background v-else-if="trainerView === 'background'"/>
    <selection-result v-else-if="trainerView === 'result'"/>
  </div>
</template>

<script>

import DefineTrainer from "@/views/select/trainer/components/defineTrainer";
import DefineLesson from "@/views/select/trainer/components/defineLesson";
import SelectBackground from "@/views/select/trainer/components/selectBackground";
import SelectionResult from "@/views/select/trainer/components/selectionResult";

export default {
  name: "index",
  components: {SelectionResult, SelectBackground, DefineLesson, DefineTrainer},
  computed: {
    trainerView() {
      return this.$store.getters['getView'].replace('select-trainer-', '');
    }
  },
}
</script>

<style scoped lang="scss">
</style>
