import notificationPanel from './notification-panel.vue'
import {module} from "@/plugins/notification-panel/module";

const components = [
  notificationPanel
];

export default {
  install(app, options) {
    if (!options || !options.Store) {
      throw new Error('Please initialise plugin with a Vuex store.');
    }

    options.Store.registerModule('notify', module)

    components.forEach((e) => {
      app.component(e.name, e);
    });

    app.config.globalProperties.$notify = {

      success(message) {
        options.Store.commit('notify/ADD_NOTIFICATION', {
          'type': 'success',
          'message': message,
          'title': 'Success'
        })
      },

      error(message) {
        options.Store.commit('notify/ADD_NOTIFICATION', {
          'type': 'error',
          'message': message,
          'title': 'Error occurred'
        })
      }

    }
  }
};
