<template>
  <div class="video-select-container">
    <bf-video-item v-for="(item, index) in items" :key="`video-item-${index}`" @click="onSelect(item)" :item="item"/>
  </div>
</template>

<script>

import BfVideoItem from "@/components/bf-video-item";

export default {
  name: "bf-video-select",
  components: {BfVideoItem},
  props: {
    items: {required: true, type: Array}
  },
  computed: {
    type() {
      return this.$store.getters['usageType']
    },
  },
  methods: {
    onSelect(item) {
      this.$emit('onSelect');
      this.$store.commit('Session/setSelectedItem', item);
      this.$store.dispatch('SAVE_SESSION');
    }
  }
}
</script>

<style scoped lang="scss">

.video-select-container {
  flex: 1;
  overflow-y: scroll;
  margin-bottom: 60px;
}

</style>
