<template>
  <div class="container">

    <bf-video-select :items="filteredItems" v-if="!item"/>

    <div class="selected-video" v-else>
      <bf-video-item :item="item"/>
      <div class="actions">
        <bf-button @onClick="startWorkout" :disabled="disabled">{{ $t('trainer-start') }}</bf-button>
        <bf-button @onClick="stopWorkout" :disabled="disabled">{{ $t('trainer-stop') }}</bf-button>
      </div>
    </div>

    <div class="buttons">
      <bf-button @onClick="goBack" type="secondary" :disabled="disabled || isPlaying">
        {{ $t('back') }}
      </bf-button>
    </div>
  </div>
</template>

<script>
import BfButton from "@/components/bf-button";
import BfVideoSelect from "@/components/bf-video-select";
import BfVideoItem from "@/components/bf-video-item";

export default {
  name: "index",
  components: {BfVideoItem, BfVideoSelect, BfButton},
  data() {
    return {
      disabled: false,
      disabledTimeout: undefined
    }
  },

  computed: {
    isPlaying() {
      return this.$store.getters['Session/isPlaying']
    },

    canSend() {
      return this.$store.state.Session.socket && !this.disabled;
    },

    item() {
      return this.$store.getters['Session/getSelectedItem']
    },

    filteredItems() {
      return this.$store.getters['Session/getMedia']
          .filter((i) => {
        let index = i.meta_data.findIndex((m) => m.name === 'MediaItem.VW_Category');
        if (index !== -1) {
          return i.meta_data[index].value.toLowerCase() === 'employee'
        } else {
          return false;
        }
      })
    }
  },

  mounted() {
    // this.$store.dispatch('SET_VIEW', this.$route.name)
  },

  beforeUnmount() {
    this.clearDisabled()
  },

  methods: {

    setDisabled() {
      this.disabled = true;
      this.disabledTimeout = setTimeout(() => {
        this.clearDisabled()
      }, 1000)
    },

    clearDisabled() {
      if (this.disabledTimeout) {
        clearTimeout(this.disabledTimeout);
        this.disabledTimeout = undefined;
        this.disabled = false;
      }
    },

    checkSession() {
      return this.$api.get(`/api/players/${this.$store.getters['Session/getPlayerUuid']}/active-session`)
          .then((res) => {
            if (res.data.session_uuid !== this.$store.getters['Session/getSessionUuid']) {
              throw new Error('Session does not match')
            }
          })
          .catch((e) => {
            if (e.response?.status === 403) {
              this.$notify.error("Session expired");
            } else {
              if (typeof e === 'string') {
                this.$notify.error(e);
              } else {
                this.$notify.error("Something went wrong");
              }
            }
            this.$store.dispatch('RESET')
            this.$store.dispatch('SET_VIEW','home')
            // this.$router.push('/')
          })
    },

    startWorkout() {
      this.checkSession()
          .then(() => {
            if (this.canSend) {
              this.$store.commit('Session/setIsPlaying', true);
              this.setDisabled();
              let payload = {
                type: 'playVideo',
                data: {
                  media_id: this.item.id,
                  lesson: this.$store.getters['getLesson']
                }
              }

              this.$store.dispatch('Session/SOCKET_SEND', payload);
              this.$store.dispatch('SAVE_SESSION');
            }
          })
    },

    stopWorkout() {
      this.checkSession()
          .then(() => {
            if (this.canSend) {
              this.setDisabled();
              this.$store.commit('Session/setIsPlaying', false);
              let payload = {
                type: 'stopVideo',
                data: {
                  media_id: this.item.id
                }
              }
              this.$store.dispatch('Session/SOCKET_SEND', payload);
              this.$store.dispatch('SAVE_SESSION');
            }
          })
    },

    goBack() {
      if (this.item) {
        this.$store.dispatch('Session/CLEAR_SELECTED')
        this.$store.dispatch('SAVE_SESSION')
      } else {
        this.$store.dispatch('SET_TYPE', undefined)
        this.$store.dispatch('SET_VIEW','select-start')
        // this.$router.push('/select')
      }
    }
  }
}
</script>

<style scoped lang="scss">
.buttons {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #FFF;
  height: 80px;
  box-sizing: border-box;

  button {
    position: absolute;
    bottom: .7em;
  }
}
</style>
