<template>
  <div class="start-screen">
    <div class="start-panel">
      <qr-scanner-inline @scanned="scannedCode" key="second" v-if="ready"/>

      <transition name="fade">
        <div class="buttons" v-if="displayButton">
          <bf-button @onClick="manualStart" :disabled="disabled" class="start" v-html="$t('qr-unavailable')">

          </bf-button>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import QrScannerInline from "@/components/qr-scanner-inline";
import BfButton from "@/components/bf-button";

export default {
  name: "scan",
  components: {BfButton, QrScannerInline},

  data() {
    return {
      ready: false,
      disabled: false,
      displayButtonTimeout: undefined,
      displayButton: false,
    }
  },

  beforeUnmount() {
    if (this.displayButtonTimeout) {
      clearTimeout(this.displayButtonTimeout);
      this.displayButtonTimeout = undefined;
    }
  },

  mounted() {
    this.setupWebSocket()
  },

  methods: {
    setupWebSocket() {
      this.$store.dispatch('Session/SETUP_SOCKET', {cb: this.checkSession})
          .then(() => {
            this.ready = true;
            this.displayButtonTimeout = setTimeout(() => {
              this.displayButton = true;
            },10000)
          })
          .catch((err) => {
            console.log("ERROR", err)
          })
    },

    manualStart() {
      if (!this.disabled) {
        this.disabled = true;
        this.$store.dispatch('Session/SOCKET_SEND',
            {
              type: 'startSession',
              data: {
                manual: true
              }
            }
        )
      }
    },

    scannedCode(result) {
      let valid = true;

      // Check if result is not a URL
      if (typeof result === 'string') {
        valid = result.match(/(^http(s?):\/\/)/) === null;
      }

      if (!this.disabled && valid) {
        this.disabled = true;

        this.$store.dispatch('Session/SOCKET_SEND',
            {
              type: 'startSession',
              data: {
                manual: false
              }
            }
        )
      }
    },

    async checkSession() {
      // Get active session
      await this.$api.get(`/api/players/${this.$store.getters['Session/getPlayerUuid']}/active-session`)
          .then((res) => {

            if (res.data.session_uuid !== this.$store.getters['Session/getSessionUuid']) {
              this.$notify.error('Session does not match');
              throw new Error("Session does not match");
            } else {
              this.$store.dispatch('Session/SOCKET_SEND', {
                type: 'displayReady',
                data: {}
              })

              this.$nextTick(() => {
                this.$store.dispatch('SET_VIEW', 'select-start');
              })
            }
          })
          .catch((e) => {
            this.$notify.error(e.message)
            this.$store.dispatch('RESET')
            this.$store.dispatch('SET_VIEW', 'home')
          })
    },
  }
}
</script>

<style scoped lang="scss">

.start-screen {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 1em;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
  }

  .start-panel {
    position: relative;
    z-index: 2;
    background: #fff;
    border-radius: 1em;
    padding: 0 2em 2em;

    .description {
      font-size: 1.5em;
      text-align: center;
      line-height: 1.2em;
      font-weight: 400;
    }

    .buttons {
      position: fixed;
      left: 50%;
      transform: translateX(-50%);
      bottom: 2em;

      .start {
        font-size: .75em;
        padding: 0.7em 1em;
        background: none;
        color: #fff;
        text-transform: initial !important;
        line-height: 140%;
        text-align: center;
        font-weight: 400;
        margin: 0;
        white-space: nowrap;
        text-shadow: 1px 1px rgba(0,0,0,0.5);
        opacity: .75;

        &:active {
          //background: #4b238c;
        }
      }

    }
  }
}

.fade-enter-active {
  transition: opacity ease-in-out .25s;
}

.fade-enter-from {
  opacity: 0;
}

.fade-enter-to {
  opacity: 1;
}

</style>
