<template>
  <div style="display:flex;flex-flow:column;height:100%;background:#FFF;">
    <bf-header/>

    <div style="margin-top: 80px;">
      <start v-if="selectView === 'start'"/>
      <employee v-else-if="selectView === 'employee'"/>
      <trainer v-else-if="selectView.includes('trainer')"/>
    </div>
  </div>
</template>

<script>
import BfHeader from "@/components/bf-header";
import axios from "axios";
import Start from "@/views/select/start";
import Trainer from '@/views/select/trainer/index'
import Employee from '@/views/select/employee/index'

export default {
  name: "index.vue",
  components: {Start, BfHeader, Trainer, Employee},
  data() {
    return {
      ready: false,
    }
  },

  computed: {
    selectView() {
      return this.$store.getters['getView'].replace('select-', '');
    }
  },

  mounted() {
    if (this.$store.getters['Session/getSessionReady']) {
      this.getMedia()
    } else {
      this.reset();
    }
  },

  methods: {
    reset() {
      this.$store.dispatch('RESET');
      this.$store.dispatch('SET_VIEW', 'home');
    },

    getMedia() {
      this.$api.get(`/api/sessions/${this.$store.getters['Session/getSessionUuid']}/get-media`)
          .then(res => {
            this.$store.commit('Session/setMedia', res.data.media);
            this.ready = true;
          })
          .catch(err => {
            this.$notify.error(err.message)
            this.reset();
          })
    },
  }
}
</script>

<style scoped lang="scss">
</style>
