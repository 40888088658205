<template>
  <div class="form-container"  v-if="ready">
    <div class="form">
      <input-scroll-select v-model:value="name" :label="$t('trainer-form-name-lesson')" :required="true"
                           :options="lessonNames" :search="true"/>

      <input-scroll-select v-model:value="duration" :label="$t('trainer-form-length-workout')" :required="true"
                           :options="timeOptions"
                           :format="`{value} ${$t('trainer-form-minutes-short')}`"
                           :modalTitle="$t('trainer-time-select-minute-amount')"
      />

      <input-checkbox v-model:value="nameVisible" :label="$t('trainer-form-name-visible')"/>
      <input-checkbox v-model:value="timeVisible" :label="$t('trainer-form-time-visible')"/>
    </div>

    <div>
      <bf-button @onClick="clearAndBack" type="secondary">
        {{ $t('back') }}
      </bf-button>

      <bf-button :disabled="!canClick" @onClick="storeLessonAndNext">
        {{ $t('next') }}
      </bf-button>
    </div>
  </div>
</template>

<script>
import BfButton from "@/components/bf-button";
import InputCheckbox from "@/components/inputs/input-checkbox";
import InputScrollSelect from "@/components/inputs/input-scroll-select";

export default {
  name: "defineLesson",
  components: {InputScrollSelect, InputCheckbox, BfButton},
  data() {
    return {
      name: undefined,
      duration: 30,
      nameVisible: true,
      timeVisible: true,
      ready: false,
      lessons: []
    }
  },
  computed: {
    lessonNames() {
      return this.lessons.map((l) => l.name);
    },

    timeOptions() {
      let options = [];

      for (let i = 1; i <= 120; i++) {
        options.push(i);
      }

      return options;
    },

    canClick() {
      return this.name !== undefined
    }
  },
  mounted() {
    this.fetch()
  },

  methods: {
    fetch() {
      this.$api.get('/api/lessons', {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then((res) => {
            this.lessons = res.data;
            this.ready = true;
          })
          .catch((e) => {
            // Something wetn wrong use fake data for now
            // TODO: REMOVE FAKE DATA
            let data = [
              {
                "id": 4,
                "name": "Lesson 1",
                "created_at": "2022-09-07T12:56:26.000000Z",
                "updated_at": "2022-09-07T12:56:26.000000Z"
              },
              {
                "id": 5,
                "name": "Lesson 2",
                "created_at": "2022-09-07T12:56:29.000000Z",
                "updated_at": "2022-09-07T12:56:29.000000Z"
              },
              {
                "id": 6,
                "name": "Lesson asdf",
                "created_at": "2022-09-07T12:56:34.000000Z",
                "updated_at": "2022-09-07T12:56:34.000000Z"
              }
            ];
            this.lessons = data;
            this.ready = true;

          })

    },

    clearAndBack() {
      this.$store.dispatch('SET_VIEW', 'select-trainer-trainer');
    },

    storeLessonAndNext() {
      this.$store.dispatch('STORE_LESSON', {
        name: this.name,
        duration: this.duration,
        displayTrainerName: this.nameVisible,
        displayDuration: this.timeVisible
      })

      this.$nextTick(() => {
        this.$store.dispatch('SET_VIEW', 'select-trainer-background');
      })
    }
  }
}
</script>

<style scoped>

</style>
