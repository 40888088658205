<template>
  <div class="container">
    <div class="option black pro-treble" @click="goTo('trainer')">
      <p>
        {{ $t('trainer-live-classes') }}
      </p>
    </div>
    <div class="option orange pro-treble" @click="goTo('employee')">
      <p>
        {{ $t('trainer-educational') }}
      </p>
    </div>

    <input-select :options="$getLocales()" :width="'fit-content'" style="minWidth: 50%;"
                  :defaultOption="$store.state.lang" @onSelect="changeLanguage"/>
  </div>
</template>

<script>
import InputSelect from "@/components/inputs/input-select";

export default {
  name: "start",
  components: {InputSelect},

  methods: {
    goTo(type) {
      this.$store.dispatch('SET_TYPE', type);

      let view = `select-${type}`
      if (type === 'trainer') {
        view += `-trainer`
      }

      this.$store.dispatch('SET_VIEW', view)
    },

    changeLanguage(lang) {
      this.$setLocale(lang);
    }
  }
}
</script>

<style scoped lang="scss">

.container {
  padding: 3em 1em;
}

.option {
  width: 100%;
  height: 195px;
  margin-bottom: 2em;
  color: #FFF;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;

  align-items: center;


  &.black {
    background-image: url('../../assets/img/panel-dark.png');
  }

  &.orange {
    background-image: url('../../assets/img/panel-orange.png');
  }

  p {
    font-size: 30px;
    margin: .75em;
    max-width: 60%;
    line-height: 120%;
    font-weight: 600;
    text-transform: uppercase;
  }
}

</style>
