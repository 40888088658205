<template>
  <div style="height:100%;">
    <notification-panel/>
    <template v-if="view">
      <scan-first v-if="view === 'home'"/>
      <scan-second v-else-if="view === 'scan-confirm'"/>
      <assign_qr v-else-if="view === 'assign-club'"/>
      <SelectView v-else-if="view.includes('select')"/>
    </template>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import ScanFirst from '@/views/scan_first.vue';
import ScanSecond from '@/views/scan_second.vue';
import SelectView from '@/views/select/index.vue';
import Assign_qr from "@/views/assign_qr";

export default {
  name: 'App',
  components: {Assign_qr, ScanFirst, ScanSecond, SelectView},
  data() {
    return {
      ready: false,
    }
  },

  mounted() {
    this.checkForQR();
  },

  computed: {
    ...mapGetters({
      'view': 'getView'
    })
  },

  methods: {

    async checkForQR() {
      const [origin, query] = window.location.href.split('?');
      if (query) {
        const params = new URLSearchParams(query);
        if (params.has('cqr')) {

          // Store and history replacestate
          const currentState = {...history.state}
          let newState = {...currentState};
          newState.current = null;

          // Store the QR code
          this.$store.dispatch('STORE_CQR', params.get('cqr'))

          // Goto the second scan view
          await this.$store.dispatch('VALIDATE_QR', this.$api)
              .then(() => {
                this.$setLocale(this.$store.state.lang)
                this.$store.dispatch('SET_VIEW', 'scan-confirm')
                history.replaceState(newState, '', window.location.origin + '/#/');
              })

        }
      } else {
        // Check for stored data
        await this.validateStoredData()
      }
    },

    async validateStoredData() {
      // Check for session uuid in storage
      const storedData = localStorage.getItem(process.env.VUE_APP_SESSION_STORAGE_KEY);
      // If session uuid is found check
      if (storedData) {
        const parsed = JSON.parse(storedData);
        if (parsed.puuid) {

          await this.$api.get(`/api/players/${parsed.puuid}/active-session`)
              .then((res) => {
                // If active restore remaining store data (lesson, items) and navigate to correct page
                if (res.data.session_uuid === parsed.suuid) {
                  // Active session same as stored one

                  // Restore data
                  this.$store.dispatch('RESTORE_SESSION', parsed);
                  this.$setLocale(this.$store.state.lang)

                  return parsed;

                } else {
                  // If not active notify expired / invalid session and reset all the things
                  throw new Error("Session expired");
                }
              })
              .then(async (data) => {
                // Restore websocket connection
                if (data.view !== 'home') {

                  // Setup socket if the view isn't home otherwise the second scan will do it for us
                  return await this.$store.dispatch('Session/SETUP_SOCKET', {cb: undefined})
                      .then(() => {
                        // Figure out what the last route what, store it separately perhaps?
                        this.$nextTick(() => {
                          this.$store.dispatch('SET_VIEW', data.view);
                        })
                      })
                      .catch((err) => {
                        console.log('failed to setup websocket', err);
                        throw err
                      })

                }
              })
              .catch((err) => {
                this.clearAll();
              })
        } else {
          this.$store.dispatch('SET_VIEW','home');
        }
      } else {
        this.$store.dispatch('SET_VIEW','home');
      }
    },

    clearAll() {
      this.$store.dispatch('RESET');
      localStorage.clear();
      window.location = '/';
    }
  }
}
</script>

<style lang="scss">

@font-face {
  font-family: ProTreble;
  src: url(~@/assets/fonts/Heading-Pro-Treble-Regular.woff) format("woff");
  font-weight: 400;
}

@font-face {
  font-family: ProTreble;
  src: url(~@/assets/fonts/Heading-Pro-Treble-Light.woff) format("woff");
  font-weight: 300;
}

@font-face {
  font-family: ProTreble;
  src: url(~@/assets/fonts/Heading-Pro-Treble-Heavy.woff) format("woff");
  font-weight: 800;
}

@font-face {
  font-family: ProTreble;
  src: url(~@/assets/fonts/Heading-Pro-Treble-ExtraBold.woff) format("woff");
  font-weight: 600;
}

@font-face {
  font-family: ProDouble;
  src: url(~@/assets/fonts/Heading-Pro-Double-Regular.woff) format("woff");
  font-weight: normal;
}

.pro-double {
  font-family: ProDouble;
}

.pro-treble {
  font-family: ProTreble;
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('../design/fonts/montserrat-v24-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url('../design/fonts/montserrat-v24-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  src: url('../design/fonts/montserrat-v24-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
}

@import '@/design/_reset';
@import '@/design/_globals';
</style>
