<template>
  <div class="form-container">
    <bf-video-item :item="item" :display-title="false" v-if="item"/>

    <div class="label">
      {{ $t('trainer-ready-message') }}
    </div>

    <div class="result" v-if="lesson">
      <div class="result-line">
        <div class="result-label">{{ $t('trainer-result-trainer') }}:</div>
        <div class="result-value">{{ lesson.trainerName }}</div>
      </div>

      <div class="result-line">
        <div class="result-label">{{ $t('trainer-form-name-lesson') }}:</div>
        <div class="result-value">{{ `${lesson.trainerTrainingName}` }}</div>
      </div>
      <div class="result-line">
        <div class="result-label">{{ $t('trainer-time-select-minute-amount') }}:</div>
        <div class="result-value">{{ `${lesson.trainerTime}` }}</div>
      </div>
      <div class="result-line">
        <div class="result-label">{{ $t('trainer-result-display-name') }}:</div>
        <div class="result-value">
          <awesome-icon :icon="lesson.trainerShowName ? 'check' : 'times'"/>
        </div>
      </div>
      <div class="result-line">
        <div class="result-label">{{ $t('trainer-result-display-time') }}:</div>
        <div class="result-value">
          <awesome-icon :icon="lesson.trainerShowTime ? 'check' : 'times'"/>
        </div>
      </div>
      <div class="result-line">
        <div class="result-label">{{ $t('trainer-result-language') }}:</div>
        <div class="result-value">{{ `${lesson.trainerLanguage}` }}</div>
      </div>
      <div class="result-line">
        <div class="result-label">{{ $t('trainer-result-name-light-plan') }}:</div>
        <div class="result-value">{{ item.name }}</div>
      </div>
    </div>

    <div class="buttons">
      <div class="flexed-buttons">
        <bf-button @onClick="startWorkout" style="" :disabled="disabled">{{ $t('trainer-start') }}</bf-button>
        <bf-button @onClick="stopWorkout" style="margin-right: 0;" :disabled="disabled">{{
            $t('trainer-stop')
          }}
        </bf-button>
      </div>
      <bf-button @onClick="startNewWorkout" style="margin: 0;" :disabled="disabled">
        {{ $t('trainer-start-new-workout') }}
      </bf-button>
      <br>
      <br>
      <bf-button @onClick="back" style="margin: 0;" type="secondary" :disabled="playing">
        {{ $t('back') }}
      </bf-button>
    </div>
  </div>
</template>

<script>
import BfButton from "@/components/bf-button";
import BfVideoItem from "@/components/bf-video-item";

export default {
  name: "selectionResult",
  components: {BfVideoItem, BfButton},
  data() {
    return {
      disabled: false,
      disabledTimeout: undefined,
    }
  },

  computed: {
    canSend() {
      return this.$store.state.Session.socket && !this.disabled;
    },
    item() {
      return this.$store.getters['Session/getSelectedItem']
    },
    lesson() {
      return this.$store.getters['getLesson'];
    },
    playing() {
      return this.$store.getters['Session/isPlaying'] || false
    }
  },

  beforeUnmount() {
    this.clearDisabled()
  },
  methods: {
    back() {
      this.$store.dispatch('SET_VIEW', 'select-trainer-background');
    },

    setDisabled() {
      this.disabled = true;
      this.disabledTimeout = setTimeout(() => {
        this.clearDisabled()
      }, 1000)
    },

    clearDisabled() {
      if (this.disabledTimeout) {
        clearTimeout(this.disabledTimeout);
        this.disabledTimeout = undefined;
        this.disabled = false;
      }
    },

    checkSession() {
      return this.$api.get(`/api/players/${this.$store.getters['Session/getPlayerUuid']}/active-session`)
          .then((res) => {
            if (res.data.session_uuid !== this.$store.getters['Session/getSessionUuid']) {
              throw new Error('Session does not match')
            }
          })
          .catch((e) => {
            if (e.response?.status === 403) {
              this.$notify.error("Session expired");
            } else {
              if (typeof e === 'string') {
                this.$notify.error(e);
              } else {
                this.$notify.error("Something went wrong");
              }
            }
            this.$store.dispatch('RESET')
            this.$store.dispatch('SET_VIEW', 'home');
          })
    },

    startWorkout() {
      this.checkSession()
          .then(() => {
            if (this.canSend) {
              this.setDisabled();
              this.$store.commit('Session/setIsPlaying', true);
              let payload = {
                type: 'playVideo',
                data: {
                  media_id: this.item.id,
                  lesson: this.$store.getters['getLesson']
                }
              }

              this.$store.dispatch('Session/SOCKET_SEND', payload);
              this.$store.dispatch('SAVE_SESSION');
            }
          })
    },

    stopWorkout() {
      this.checkSession()
          .then(() => {
            if (this.canSend) {
              this.setDisabled();
              this.$store.commit('Session/setIsPlaying', false);
              let payload = {
                type: 'stopVideo',
                data: {
                  media_id: this.item.id
                }
              }
              this.playing = false;
              this.$store.dispatch('Session/SOCKET_SEND', payload);
              this.$store.dispatch('SAVE_SESSION');
            }
          })
    },

    startNewWorkout() {
      this.checkSession()
          .then(() => {
            this.$store.dispatch('RESET_LESSON');
            this.$store.dispatch('SET_VIEW','select-trainer-lesson');
          })
    }
  }
}
</script>

<style scoped lang="scss">

.result-line {
  display: flex;
  line-height: 130%;

  .result-label {
    flex: 2;
    margin-right: 1em;
  }
;

  .result-value {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

.buttons {
  width: fit-content;
  margin-top: 1em;

  .flexed-buttons {
    display: flex;
    flex-flow: wrap;
    margin-bottom: 1em;
    justify-content: space-between;

    button {
      flex: 1;
    }
  }
}

</style>
