<template>
  <div style="display:flex;flex-flow:column;height:100%;">
    <bf-header/>

    <div class="container" style="margin-top:80px;">

      <input-field v-model:value="kpNumber" label="KP Number" placeholder="Enter KP Number"/>

      <div style="margin-bottom: 2em;">
        <bf-button :disabled="!kpNumber || processing" @onClick="lookupByKP">
          Search
        </bf-button>
      </div>

      <template v-if="searched">

        <div class="label" style="margin-bottom: .5em;">
          {{ items.length === 0 ? 'No results' : 'Club found' }}
        </div>

        <div class="club" v-for="(item, index) in items" :key="`item-${index}`">
          <div class="club-line">
            <div class="tag">
              Name
            </div>
            <div class="value">
              {{ getMetadataValue(item, 'Player.Club_Name', item.name) }}
            </div>
          </div>

          <div class="club-line">
            <div class="tag">
              City
            </div>
            <div class="value">
              {{ getMetadataValue(item, 'Player.City', item.name) }}
            </div>
          </div>

          <div class="club-line">
            <div class="tag">
              Address
            </div>
            <div class="value">
              {{ getMetadataValue(item, 'Player.Adress', item.name) }}
            </div>
          </div>


          <div style="margin-top: 1em;">
            <bf-button style="flex-shrink:0;" :disabled="processing" @onClick="assignQrToClub(item)"
                       v-if="item.connected === 0">
              Assign QR
            </bf-button>

            <div v-else>
              Club already assigned
            </div>
          </div>
        </div>

      </template>

    </div>
  </div>
</template>

<script>
import BfHeader from "@/components/bf-header";
import InputField from "@/components/inputs/input-field";
import BfButton from "@/components/bf-button";

export default {
  name: "assign_qr",
  components: {BfButton, InputField, BfHeader},
  data() {
    return {
      kpNumber: undefined,
      processing: false,
      items: [],
      searched: false
    }
  },
  beforeMount() {
    // if (!this.$store.getters['cqr']) {
    //   this.$router.push('/')
    // }
  },
  methods: {
    getMetadataValue(item, name, placeholder = 'none') {
      let meta = item.metadata.find((m) => m.name === name);
      return meta ? meta.value : placeholder;
    },

    lookupByKP() {
      this.processing = true;

      this.$api.get(`/api/player-by/kp_number/${this.kpNumber}`)
          .then((res) => {
            this.items = res.data;
            this.processing = false;
            this.searched = true;
          })
          .catch((e) => {
            this.processing = false;
            this.searched = true;
          })
    },

    assignQrToClub(item) {
      this.processing = true;

      this.$api.post('/api/corporate-qr/attach-to-player', {
            "cqr": this.$store.getters['cqr'],
            "player_uuid": item.cm_player_uuid
          })
          .then(() => {
            this.$notify.success("QR assigned successfully");
            this.$store.dispatch('SET_VIEW', 'home')
            // this.$router.push('/');
          })
          .catch((e) => {
            this.processing = false;
            this.$notify.error('Unable to assign QR code')
            this.$store.dispatch('SET_VIEW', 'home')
            // this.$router.push('/')
          })
    }
  }
}
</script>

<style scoped lang="scss">

.club {
  margin-bottom: 2em;

  .club-line {
    display: flex;
    line-height: 140%;

    .tag {
      width: 100px;
    }

    .value {
      flex: 1;
    }
  }
}

</style>
