<template>

  <div class="app-filter pro-double" ref="select" :style="{width: width}">
    <div class="filter-input capitalize" :id="`filter-${type}`" @click="toggleOpenState"
         :class="{active: displayOptions}">
      <span :style="!selected ? {color: 'rgb(45 45 45 / 40%)', textTransform: 'lowercase', fontWeight: 500} : {}">
        {{ selected || $t('choose-an-option')}}
      </span>
      <awesome-icon icon="chevron-down" style="margin-left: 1em;"/>
    </div>

    <div class="filter-options" :class="type" v-if="displayOptions" ref="options">
      <div class="filter-option" :class="type" v-for="(o,i) in options"
           :key="`option-${i}`"
           @click.stop.prevent="selectOption(o)">
        <span class="filter-value capitalize">
          {{ o }}
        </span>
        <awesome-icon icon="check" v-if="isSelected(o)" class="ml-3"/>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "input-select",
  props: {
    type: {default: 'normal'},
    options: {required: true, type: Array},
    defaultOption: {type: String},
    width: {default: '100%'},

  },
  data() {
    return {
      displayOptions: false,
      bounds: undefined,
      selected: undefined
    }
  },

  beforeMount() {
    if (this.defaultOption) {
        this.selected = this.defaultOption
    }
  },

  methods: {
    isSelected(value) {
      return this.selected === value;
    },

    toggleOpenState() {
      this.displayOptions = !this.displayOptions;
      this.scroll();
    },

    selectOption(o) {
      this.selected = o;
      this.displayOptions = false;
      this.$emit('onSelect', this.selected);
      this.scroll();
    },

    scroll() {
      this.$nextTick(() => {
        let targetScroll = this.displayOptions ? this.$refs.options.scrollHeight : this.$refs.select.scrollHeight;
        window.scrollTo(0, targetScroll);
      })
    }
  }
}

</script>

<style scoped lang="scss">

.app-filter {
  cursor: pointer;
  box-sizing: border-box;
  text-transform: uppercase;
  margin-bottom: 2em;

  .filter-input {
    background: #ece9e8;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.25em;

    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: bold;
    }

    &.active {
      color: #FFF;
      background: $color-primary;
    }
  }

  .filter-options {
    position: relative;
    background: #f4f4f4;
    z-index: 1;
    width: 100%;
    box-shadow: var(--vc-shadow-light);
    box-sizing: border-box;
    margin-bottom: 1em;

    .filter-option {
      padding: .75em .6em;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #e1e1e1;

      &:last-child {
        border-bottom: none;
      }

      .filter-value {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

</style>
