import {createRouter, createWebHashHistory} from 'vue-router'

import scan from '../views/scan_first.vue';
import scanConfirm from '../views/scan_second.vue';
import assignQR from '../views/assign_qr.vue';

import select from '../views/select/index';
import selectStart from '../views/select/start.vue'
import selectTrainer from '../views/select/trainer/index'
import selectEmployee from '../views/select/employee/index'

const routes = [
  {
    path: '/',
    name: 'home',
    component: scan,
  },
  {
    path: '/scan-confirm',
    name: 'confirm',
    component: scanConfirm,
  },
  {
    path: '/assign-club',
    name: 'assign',
    component: assignQR
  },
  {
    path: '/select',
    name: 'select',
    component: select,
    children: [
      {
        name: 'start',
        path: '',
        component: selectStart
      },
      {
        name: 'employee',
        path: 'employee',
        component: selectEmployee
      },
      {
        name: 'trainer',
        path: 'trainer',
        component: selectTrainer,
        children: [
          {
            name: 'define-trainer',
            path: '',
            component: () => {
              return import('@/views/select/trainer/components/defineTrainer')
            }
          },
          {
            name: 'define-lesson',
            path: 'lesson',
            component: () => {
              return import('@/views/select/trainer/components/defineLesson')
            }
          },
          {
            name: 'background',
            path: 'background',
            component: () => {
              return import('@/views/select/trainer/components/selectBackground')
            }
          },
          {
            name: 'result',
            path: 'result',
            component: () => {
              return import('@/views/select/trainer/components/selectionResult')
            }
          }
        ]
      }
    ]
  },
  // {
  //   path: '/override',
  //   component: override,
  // },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/'
  }
]

const Router = createRouter({
  history: createWebHashHistory(),
  routes
})
//
// const router = new VueRouter({
//   routes
// })

export default Router
