// Create a new store instance.
const Session = {
  namespaced: true,
  state() {
    return {
      stickerScanned: false,
      connected: false,

      socket: undefined,

      session_uuid: undefined,
      player_uuid: undefined,

      media: [],
      selectedItem: undefined,
      response: undefined,

      isPlaying: false
    }
  },
  actions: {
    SOCKET_SEND({state}, message) {
      message = typeof message === 'object' ? JSON.stringify(message) : message;
      state.socket.send(message);
    },
    CLEAR_SELECTED({commit}) {
      commit('clearSelected')
    },

    STORE_SESSION_RESPONSE({commit, dispatch}, data) {
      commit('setNewSessionResponse', data);
      dispatch('SET_LANG', data.player_language, {root: true});
      dispatch('SAVE_SESSION', null, {root: true})
    },

    SETUP_SOCKET({commit, dispatch, state, rootState}, {cb}) {
      return new Promise((resolve, reject) => {
        if (!"WebSocket" in window) {
          reject({message: 'Websockets not supported'})
        } else {

          let url = `${process.env.VUE_APP_URL_SOCKET}/socket?type=trainer&pi=${state.player_uuid}&si=${state.session_uuid}`;

          let socket = new WebSocket(url);

          socket.onmessage = (evt) => {
            let message = JSON.parse(evt.data);

            if (message.type === 'newUserConnected') {
              commit('setConnected', true);
              if (typeof cb === 'function') {
                cb()
              }
            }

            if (message.type === 'ping') {
              socket.send(JSON.stringify({type: 'pong'}))
            }
          }

          socket.onopen = () => {
            //
            if (!['home','scan-confirm'].includes(rootState.routerView)) {
              if (!state.isPlaying) {
                dispatch('SOCKET_SEND', {
                  type: 'displayReady',
                  data: {}
                })
              }
            } else {
              dispatch('SOCKET_SEND', {
                type: 'displayQR',
                data: {}
              })
            }
            resolve();
          }

          socket.onclose = () => {
            dispatch('SETUP_SOCKET', {cb: cb})
          }

          commit('setSocket', socket);
        }
      })
    }
  },

  mutations: {
    setNewSessionResponse(state, data) {
      state.stickerScanned = true;
      state.response = data;
      state.session_uuid = data.session_uuid;
      state.player_uuid = data.player_uuid;
    },
    setSocket(state, socket) {
      state.socket = socket;
    },
    setConnected(state, value) {
      state.connected = value;
    },
    setMedia(state, media) {
      state.media = media;
    },
    setSelectedItem(state, item) {
      state.selectedItem = item;
    },
    clearSelected(state) {
      state.selectedItem = undefined;
    },

    resetSession(state) {
      state.session_uuid = undefined;
      state.player_uuid = undefined;
      state.stickerScanned = false;
      state.connected = false;
      state.media = [];
      state.selectedItem = undefined;
      state.socket = undefined;
      state.response = undefined;
      state.isPlaying = false;
    },

    restoreSession(state, data) {
      state.connected = data.connected;
      state.isPlaying = data.isPlaying;
      state.media = data.media;
      state.player_uuid = data.player_uuid;
      state.response = data.response;
      state.session_uuid = data.session_uuid;
      state.stickerScanned = data.stickerScanned
      state.selectedItem = data.selectedItem;
    },

    setIsPlaying(state, value) {
      state.isPlaying = value;
    }
  },
  getters: {
    getPlayerUuid(state) {
      return state.player_uuid;
    },
    getSessionUuid(state) {
      return state.session_uuid;
    },
    getSessionReady(state) {
      return state.stickerScanned && state.connected
    },
    socket(state) {
      return state.socket
    },
    getMedia(state) {
      return state.media;
    },
    getSelectedItem(state) {
      return state.selectedItem
    },
    isPlaying(state) {
      return state.isPlaying;
    }
  }
}

export default Session;
