<template>
  <div class="header" @click="toStart">
    <div class="header-left">
      <img src="@/assets/img/logo.png"/>
    </div>
  </div>
</template>

<script>
export default {
  name: "bf-header",

  methods: {
    toStart() {
      localStorage.clear();
      this.$store.dispatch('RESET');
      this.$store.dispatch('SET_VIEW','home');
    },
  }
}
</script>

<style scoped lang="scss">

.header {
  flex-grow: 0;
  flex-shrink: 1;
  max-height: 80px;
  display: flex;
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  right: 0;
  padding: 0 0.5em;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 0 5px 4px rgba(0, 0, 0, 0.1);
  background: #fff;
  //margin-bottom: 1em;
  box-sizing: border-box;

  .header-left {
    flex: 1;
    text-transform: uppercase;
    font-size: 1.5em;
    font-weight: 800;

    .header-left-row.white {
      color: #fff;
      text-shadow: 0 0 2px rgba(0, 0, 0, 1);
      margin-top: 0.0em;
    }
  }

  .header-right {
    flex: 1;
    text-align: right;
  }

  border-top: 3px solid $color-primary;
  border-image: linear-gradient(45deg, $color-primary, $color-secondary) 1;

}

.header, .header-spacer {
  height: 5em;
}
</style>
